import { render, staticRenderFns } from "./MProcessItem.vue?vue&type=template&id=5c50c195&scoped=true"
import script from "./MProcessItem.vue?vue&type=script&lang=js"
export * from "./MProcessItem.vue?vue&type=script&lang=js"
import style0 from "./MProcessItem.vue?vue&type=style&index=0&id=5c50c195&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c50c195",
  null
  
)

export default component.exports